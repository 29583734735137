import React, { Component } from "react";
import FlexBox from "components/base/flex-box/flex-box";
import Title from "components/picker/title/title";
import PhotoPreview from "components/picker/photo-preview/photo-preview";
import PreferencesBar from "components/picker/preferences-bar/preferences-bar";
import LoaderFullscreen from "components/base/loader-fullscreen/loader-fullscreen";
import ThumbnailBar from "components/picker/thumbnail-bar/thumbnail-bar";
import axios from "axios";

window.timeoutHandler = undefined;

class ImagePickerScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: true,
      activeImagePreviewLoader: true,
    };
  }

  loadDataFromBackend = (galleryId) => {
    return axios({
      method: "GET",
      url: this.props.backendUrl + "/" + galleryId + "/",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  updateBackendState = () => {
    let galleryData = {
      uuid: this.state.galleryId,
      title: this.state.title,
      images: this.state.imagesData,
    };

    return axios({
      method: "POST",
      url: this.props.backendUrl + "/update/",
      data: galleryData,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.active === this.state.active &&
      prevState.pageLoader === this.state.pageLoader &&
      prevState.activeImagePreviewLoader === this.state.activeImagePreviewLoader
    ) {
      window.clearTimeout(window.timeoutHandler);
      window.timeoutHandler = window.setTimeout(this.updateBackendState, 2000);
    }
  };

  // Handling keyboard
  componentDidMount = async () => {
    window.addEventListener("keydown", this.handleKeyPress);

    // Skip loading data because of unknown backend url
    if (!this.props.backendUrl) {
      return;
    }

    let result = await this.loadDataFromBackend(
      this.props.match.params.galleryId
    );
    let backendData = result.data;

    this.setState({
      galleryId: backendData.uuid,
      title: backendData.title,
      imagesData: backendData.images,
      activeImageData: backendData.images[0],
      active: 0,
      printsAvailable: backendData.printsAvailable,
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleKeyPress);
  };

  handleKeyPress = (event) => {
    if (event.code === "ArrowLeft") {
      event.preventDefault();
      this.navigateTo(this.state.active - 1);
    } else if (event.code === "ArrowRight") {
      event.preventDefault();
      this.navigateTo(this.state.active + 1);
    } else if (event.code === "ArrowUp") {
      event.preventDefault();
      this.setPickImage(this.state.active, true);
    } else if (event.code === "ArrowDown") {
      event.preventDefault();
      this.setPickImage(this.state.active, false);
    }
  };

  // Image picking
  setPickImage = (id, isPicked) => {
    let updatedData = [...this.state.imagesData];
    updatedData[id].picked = isPicked;
    this.setState({ data: updatedData });
  };

  togglePickImage = () => {
    var pickedState = this.state.imagesData[this.state.active].picked;
    this.setPickImage(this.state.active, !pickedState);
  };

  setComment = (id, comment) => {
    let updatedData = [...this.state.imagesData];
    updatedData[id].comment = comment;
    this.setState({ data: updatedData });
  };

  setPrintSettings = (id, diff, type, size) => {
    let updatedData = [...this.state.imagesData];

    let index = updatedData[id].prints.findIndex(
      (print) => print.type === type && print.size === size
    );

    if (index === -1 || (diff < 0 && updatedData[id].prints[index].count === 0))
      return;

    updatedData[id].prints[index].count += diff;

    this.setState({ data: updatedData });
  };

  // Handlers
  handleThumbnailsLoaded = () => {
    this.setState({ pageLoader: false });
  };

  handleThumbnailClicked = (index, item) => {
    this.navigateTo(index);
  };

  handleNavigate = (direction) => {
    this.navigateTo(this.state.active + direction);
  };

  handlePreviewImageLoaded = () => {
    this.setState({ activeImagePreviewLoader: false });
  };

  // Navigation
  navigateTo = (index) => {
    if (index < 0) index = this.state.imagesData.length - 1;
    else if (index >= this.state.imagesData.length) index = 0;

    if (index === this.state.active) return;

    let updatedData = [...this.state.imagesData];
    let newActive = index;

    updatedData[this.state.active].active = false;
    updatedData[newActive].active = true;

    this.setState({
      active: newActive,
      activeImagePreviewLoader: true,
      activeImageData: updatedData[newActive],
      imagesData: updatedData,
    });
  };

  renderPageLoader = () => {
    return this.state.pageLoader ? <LoaderFullscreen /> : "";
  };

  renderImagerPicker = () => {
    if (this.state.imagesData === undefined) return;

    return (
      <React.Fragment>
        <FlexBox>
          <Title>{this.state.title}</Title>

          <PhotoPreview
            url={this.props.backendUrl + this.state.activeImageData.imageUrl}
            showLoader={this.state.activeImagePreviewLoader}
            onLoad={this.handlePreviewImageLoaded}
            onNavRight={() => {
              this.handleNavigate(1);
            }}
            onNavLeft={() => {
              this.handleNavigate(-1);
            }}
            onDoubleClick={this.togglePickImage}
          />

          <ThumbnailBar
            backendUrl={this.props.backendUrl}
            data={this.state.imagesData}
            onClick={this.handleThumbnailClicked}
            onLoad={this.handleThumbnailsLoaded}
            activeElementIndex={this.state.active}
          />
        </FlexBox>

        <PreferencesBar
          imageNumber={this.state.active + 1}
          imagesData={this.state.imagesData}
          activeImageData={this.state.activeImageData}
          printsAvailable={this.state.printsAvailable}
          onCommentChange={(comment) => {
            this.setComment(this.state.active, comment);
          }}
          onPrintChange={(diff, type, size) => {
            this.setPrintSettings(this.state.active, diff, type, size);
          }}
          onImagePickedChanged={() => {
            this.setPickImage(
              this.state.active,
              !this.state.activeImageData.picked
            );
          }}
        />
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderPageLoader()}
        {this.renderImagerPicker()}
      </React.Fragment>
    );
  }
}

export default ImagePickerScreen;
