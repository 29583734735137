import React, { Component } from "react";
import Icon from "components/base/icon/icon";
import Loader from "react-spinners/PropagateLoader";

import styles from "./photo-preview.module.scss";

class PhotoPreview extends Component {
  constructor(props) {
    super(props);
    this.loaderColor = "#1d365d";
    this.doubleTapTime = 300;
    this.latestImageTouchTime = 0;
  }

  getLoadingHelperClass = () => {
    return this.props.showLoader ? styles.hidden : "";
  };

  handleOnImageClick = () => {
    var tapTime = new Date().getTime();

    var diff = tapTime - this.latestImageTouchTime;
    if (diff <= this.doubleTapTime) {
      this.props.onDoubleClick();
    }

    this.latestImageTouchTime = tapTime;
  };

  render() {
    return (
      <div className={styles.container}>
        <Icon
          type="arrow-left"
          onClick={this.props.onNavLeft}
          className={`${styles.navigation} ${this.getLoadingHelperClass()}`}
        />
        <img
          className={`${styles.photo} ${this.getLoadingHelperClass()}`}
          onLoad={this.props.onLoad}
          src={this.props.url}
          alt=""
          onClick={this.handleOnImageClick}
        />
        <Loader
          color={this.loaderColor}
          loading={this.props.showLoader}
          size={20}
          margin={4}
        />
        <Icon
          type="arrow-right"
          onClick={this.props.onNavRight}
          className={`${styles.navigation} ${this.getLoadingHelperClass()}`}
        />
      </div>
    );
  }
}

export default PhotoPreview;
