import React, { Component } from "react";
import axios from "axios";

class ImageUploaderScreen extends Component {
  handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    axios({
      method: "POST",
      url: this.props.backendUrl + "/upload/",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      console.log(res);
    });
  };

  render() {
    return (
      <div className="flexbox">
        <form onSubmit={this.handleSubmit}>
          <div className="flexrow">
            <div>Gallery title</div>
            <input name="galleryTitle" />
          </div>
          <div className="flexrow">
            <div>Prints available?</div>
            <input type="checkbox" name="printsAvailable" />
          </div>
          <div className="flexrow">
            <input type="file" name="images" accept=".jpg,.jpeg" multiple />
          </div>
          <button>Send</button>
        </form>
      </div>
    );
  }
}

export default ImageUploaderScreen;
