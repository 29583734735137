import React, { Component } from "react";

import styles from "./title.module.scss";

class Title extends Component {
  state = {};
  render() {
    return <div className={styles.container}>{this.props.children}</div>;
  }
}

export default Title;
