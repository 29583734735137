export const parseEnvFile = (source) => {
  const result = {};
  source.split("\n").forEach((line) => {
    if (!line.startsWith("#") && line.includes("=")) {
      const params = line.split("=");
      result[params[0]] = params[1];
    }
  });
  return result;
};

export const env = {
  vars: {},
};
