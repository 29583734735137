import React, { Component } from "react";

import ArrowLeft from "assets/icons/arrow-left.svg";
import ArrowRight from "assets/icons/arrow-right.svg";
import CheckMarkInCircleGreen from "assets/icons/checkmark-in-circle-green.svg";
import PinLine from "assets/icons/pin-line.svg";
import PinSolid from "assets/icons/pin-solid.svg";
import XInCircleRed from "assets/icons/x-in-circle-red.svg";

import styles from "./icon.module.scss";

const Icons = {
  "arrow-left": ArrowLeft,
  "arrow-right": ArrowRight,
  "checkmark-in-circle-green": CheckMarkInCircleGreen,
  "pin-line": PinLine,
  "pin-solid": PinSolid,
  "x-in-circle-red": XInCircleRed,
};

class Icon extends Component {
  render() {
    return (
      <img
        className={`${styles[this.props.size]} ${this.props.className} ${
          this.props.onClick ? styles.pointer : ""
        } `}
        alt={this.props.alt}
        src={Icons[this.props.type]}
        onClick={this.props.onClick}
        onMouseOver={(e) => {
          this.props.typeOnHover &&
            (e.currentTarget.src = Icons[this.props.typeOnHover]);
        }}
        onMouseOut={(e) => {
          this.props.typeOnHover &&
            (e.currentTarget.src = Icons[this.props.type]);
        }}
      />
    );
  }
}

export default Icon;
