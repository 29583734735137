import React, { Component } from "react";
import ImagePickerThumbnail from "../thumbnail/thumbnail";

import styles from "./thumbnail-bar.module.scss";

class ThumbnailBar extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.imagesLoaded = 0;
    this.sizes = {};
  }

  onLoad = (index, width, height) => {
    this.imagesLoaded++;
    this.sizes[index] = { width: width, height: height };

    if (this.props.onLoad && this.imagesLoaded === this.props.data.length) {
      this.props.onLoad();
    }
  };

  componentDidUpdate = () => {
    this.scrollToActiveElement();
  };

  scrollToActiveElement = () => {
    let imagesCount = Object.keys(this.sizes).length;
    if (imagesCount !== Object.keys(this.props.data).length) return;

    let windowWidth = this.container.current.clientWidth;

    let windowHalfWidth = windowWidth / 2;
    let thisWidth = this.sizes[this.props.activeElementIndex].width;
    let thisHalfWidth = thisWidth / 2;
    let beforeWidth = 0;
    for (let i = 0; i < this.props.activeElementIndex; i++) {
      beforeWidth += this.sizes[i].width;
    }
    let afterWidth = 0;
    for (let i = this.props.activeElementIndex + 1; i < imagesCount; i++) {
      afterWidth += this.sizes[i].width;
    }
    let scrollPosition = 0;
    if (beforeWidth + thisHalfWidth > windowHalfWidth) {
      scrollPosition = beforeWidth + thisHalfWidth - windowHalfWidth;
    } else if (afterWidth + thisHalfWidth < windowHalfWidth) {
      scrollPosition = windowWidth;
    }
    this.container.current.scrollLeft = scrollPosition;
  };

  render() {
    let number = 0;
    return (
      <div className={styles.container} ref={this.container}>
        {this.props.data.map((item) => {
          number++;
          let index = number - 1;
          return (
            <ImagePickerThumbnail
              key={item.uuid}
              id={item.uuid}
              index={index}
              number={number}
              picked={item.picked}
              url={this.props.backendUrl + item.thumbnailUrl}
              active={this.props.activeElementIndex === index ? true : false}
              onClick={() => this.props.onClick(index, item)}
              onLoad={this.onLoad}
            />
          );
        })}
      </div>
    );
  }
}

export default ThumbnailBar;
