import React, { Component } from "react";

import PropagateLoader from "react-spinners/PropagateLoader";

import styles from "./loader-fullscreen.module.scss";

class LoaderFullscreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaderColor: "#1d365d",
    };
  }

  setLoader = (value) => {
    this.setState({ loading: value });
  };

  render() {
    return (
      <div
        className={`${styles.container} ${
          this.state.loading ? "" : styles.hidden
        }`}
      >
        <div>
          <PropagateLoader
            color={this.state.loaderColor}
            loading={this.state.loading}
            size={20}
            margin={4}
          />
        </div>
      </div>
    );
  }
}

export default LoaderFullscreen;
