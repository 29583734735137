import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import ImagePickerScreen from "./screens/image-picker";
import ImageUploaderScreen from "./screens/image-uploader";
import ErrorScreen from "./screens/error";
import { useEnv } from "./hooks/useEnv";

function App() {
  const vars = useEnv();

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/error-404/"
          component={(props) => (
            <ErrorScreen
              errorCode={404}
              errorDesc={"Ups... Coś poszło nie tak :("}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/upload/"
          component={(props) => (
            <ImageUploaderScreen
              backendUrl={vars.REACT_APP_BACKEND_URL}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/:galleryId/"
          component={(props) => (
            <ImagePickerScreen
              backendUrl={vars.REACT_APP_BACKEND_URL}
              {...props}
            />
          )}
        />
        <Redirect to="/error-404/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
