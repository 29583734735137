import React, { Component } from "react";
import styles from "./flex-box.module.scss";

class FlexBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={`${styles.container} ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default FlexBox;
