import React, { Component } from "react";
import Icon from "components/base/icon/icon";

import styles from "./thumbnail.module.scss";

class ImagePickerThumbnail extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  renderImageNo = () => {
    return <div className={styles["image-number"]}>{this.props.number}</div>;
  };

  renderPickState = () => {
    if (this.props.picked) {
      return (
        <Icon
          className={styles["pick-state"]}
          type="checkmark-in-circle-green"
        />
      );
    }
    return <Icon className={styles["pick-state"]} type="x-in-circle-red" />;
  };

  handleOnLoaded = () => {
    if (this.props.onLoad) {
      let style = window.getComputedStyle(this.container.current);
      this.props.onLoad(
        this.props.index,
        parseFloat(style.width) +
          parseFloat(style.marginLeft) +
          parseFloat(style.marginRight) +
          parseFloat(style.paddingLeft) +
          parseFloat(style.paddingRight) +
          parseFloat(style.borderLeftWidth) +
          parseFloat(style.borderRightWidth),
        parseFloat(style.height) +
          parseFloat(style.marginTop) +
          parseFloat(style.marginBottom) +
          parseFloat(style.paddingTop) +
          parseFloat(style.paddingBottom) +
          parseFloat(style.borderTopWidth) +
          parseFloat(style.borderBottomWidth)
      );
    }
  };

  render() {
    return (
      <div
        className={`${styles.container} ${
          this.props.active ? styles.active : ""
        }`}
        onClick={this.props.onClick}
        ref={this.container}
      >
        <img
          className={styles.image}
          src={this.props.url}
          onLoad={this.handleOnLoaded}
          alt=""
        />
        {this.renderImageNo()}
        {this.renderPickState()}
      </div>
    );
  }
}

export default ImagePickerThumbnail;
