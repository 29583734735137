import React, { Component } from "react";

class FlexPair extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div>{this.props.left}</div>
        <div className={this.props.children ? this.props.nestedClassName : ""}>
          {this.props.right ? this.props.right : this.props.children}
        </div>
      </div>
    );
  }
}

export default FlexPair;
