import React, { Component } from "react";

import styles from "./switch.module.scss";

class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        onClick={this.props.onChange}
        className={
          this.props.active
            ? styles["container-active"]
            : styles["container-inactive"]
        }
      >
        <div
          className={
            this.props.active
              ? styles["button-active"]
              : styles["button-inactive"]
          }
        ></div>
      </div>
    );
  }
}

export default Switch;
