import React, { Component } from "react";

import styles from "./input-comment.module.scss";

class InputComment extends Component {
  render() {
    return (
      <div className={styles.container}>
        <textarea
          className={styles.textarea}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={(event) => {
            this.props.onChange(event.target.value);
          }}
        ></textarea>
      </div>
    );
  }
}

export default InputComment;
