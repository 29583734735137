import { useEffect, useState } from "react";
import { parseEnvFile, env } from "globals/env";

export const useEnv = () => {
  const [vars, setVars] = useState(env.vars);
  useEffect(() => {
    const parseFile = async () => {
      const file = await fetch("/env");
      const text = await file.text();
      const parsedVars = parseEnvFile(text);
      setVars(parsedVars);
    };

    if (!vars.REACT_APP_BACKEND_URL) {
      parseFile();
    }
  }, [vars]);
  return vars;
};
