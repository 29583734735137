import React, { Component } from "react";

import FlexPair from "components/base/flex-pair/flex-pair";
import InputComment from "components/base/input-comment/input-comment";
import InputNumber from "components/base/input-number/input-number";

import styles from "./preferences-bar.module.scss";
import Switch from "../../base/switch/switch";

class PreferencesBar extends Component {
  getImagesPickedSum = () => {
    return this.props.imagesData.filter((item) => item.picked).length;
  };

  getImagePrintNumber = (type, size) => {
    return this.props.activeImageData.prints.find(
      (item) => item.type === type && item.size === size
    )?.count;
  };

  getImagesPrintSum = (type, size) => {
    let pickedImages = this.props.imagesData.filter((item) => item.picked);
    return pickedImages
      .map((item) => {
        return item.prints.find((i) => i.type === type && i.size === size)
          .count;
      })
      .reduce((pv, cv) => pv + cv, 0);
  };

  renderCommentSection = (activeImageData, onCommentChange) => {
    return (
      <div className={styles.section}>
        <div className={styles.header}>Komentarze</div>
        <div className={styles["items-container"]}>
          <div className={styles["item-freesize"]}>
            <InputComment
              value={activeImageData.comment}
              placeholder="Komentarz do zdjęcia"
              onChange={onCommentChange}
            />
          </div>
        </div>
      </div>
    );
  };

  renderPrintsSection = () => {
    if (!this.props.printsAvailable) {
      return;
    }

    return (
      <div className={styles.section}>
        <div className={styles.header}>Wydruk</div>
        <div className={styles["items-container"]}>
          <FlexPair
            left="Rodzaj papieru"
            className={styles.item}
            nestedClassName={styles["right-menu-item"]}
          >
            <div>Mat</div>
            <div>Błysk</div>
          </FlexPair>

          <FlexPair
            left="Wydruk 10x15"
            className={styles.item}
            nestedClassName={styles["right-menu-item"]}
          >
            <InputNumber
              value={this.getImagePrintNumber("matt", "10x15")}
              onChange={(diff) =>
                this.props.onPrintChange(diff, "matt", "10x15")
              }
            />
            <InputNumber
              value={this.getImagePrintNumber("glossy", "10x15")}
              onChange={(diff) =>
                this.props.onPrintChange(diff, "glossy", "10x15")
              }
            />
          </FlexPair>

          <FlexPair
            left="Wydruk 13x18"
            className={styles.item}
            nestedClassName={styles["right-menu-item"]}
          >
            <InputNumber
              value={this.getImagePrintNumber("matt", "13x18")}
              onChange={(diff) =>
                this.props.onPrintChange(diff, "matt", "13x18")
              }
            />
            <InputNumber
              value={this.getImagePrintNumber("glossy", "13x18")}
              onChange={(diff) =>
                this.props.onPrintChange(diff, "glossy", "13x18")
              }
            />
          </FlexPair>

          <FlexPair
            left="Wydruk A5"
            className={styles.item}
            nestedClassName={styles["right-menu-item"]}
          >
            <InputNumber
              value={this.getImagePrintNumber("matt", "A5")}
              onChange={(diff) => this.props.onPrintChange(diff, "matt", "A5")}
            />
            <InputNumber
              value={this.getImagePrintNumber("glossy", "A5")}
              onChange={(diff) =>
                this.props.onPrintChange(diff, "glossy", "A5")
              }
            />
          </FlexPair>

          <FlexPair
            left="Wydruk A4"
            className={styles.item}
            nestedClassName={styles["right-menu-item"]}
          >
            <InputNumber
              value={this.getImagePrintNumber("matt", "A4")}
              onChange={(diff) => this.props.onPrintChange(diff, "matt", "A4")}
            />
            <InputNumber
              value={this.getImagePrintNumber("glossy", "A4")}
              onChange={(diff) =>
                this.props.onPrintChange(diff, "glossy", "A4")
              }
            />
          </FlexPair>

          <FlexPair
            left="Wydruk A3"
            className={styles.item}
            nestedClassName={styles["right-menu-item"]}
          >
            <InputNumber
              value={this.getImagePrintNumber("matt", "A3")}
              onChange={(diff) => this.props.onPrintChange(diff, "matt", "A3")}
            />
            <InputNumber
              value={this.getImagePrintNumber("glossy", "A3")}
              onChange={(diff) =>
                this.props.onPrintChange(diff, "glossy", "A3")
              }
            />
          </FlexPair>
        </div>
      </div>
    );
  };

  renderSummaryPrintsFragment = () => {
    if (!this.props.printsAvailable) {
      return;
    }

    return (
      <React.Fragment>
        <FlexPair
          left="Rodzaj papieru"
          className={styles.item}
          nestedClassName={`${styles["right-menu-item"]} ${styles["align-right"]}`}
        >
          <div>Mat</div>
          <div>Błysk</div>
        </FlexPair>

        <FlexPair
          left="Wydruk 10x15"
          className={styles.item}
          nestedClassName={`${styles["right-menu-item"]} ${styles["align-right"]}`}
        >
          <div>{this.getImagesPrintSum("matt", "10x15")} szt.</div>
          <div>{this.getImagesPrintSum("glossy", "10x15")} szt.</div>
        </FlexPair>

        <FlexPair
          left="Wydruk 13x18"
          className={styles.item}
          nestedClassName={`${styles["right-menu-item"]} ${styles["align-right"]}`}
        >
          <div>{this.getImagesPrintSum("matt", "13x18")} szt.</div>
          <div>{this.getImagesPrintSum("glossy", "13x18")} szt.</div>
        </FlexPair>

        <FlexPair
          left="Wydruk A5"
          className={styles.item}
          nestedClassName={`${styles["right-menu-item"]} ${styles["align-right"]}`}
        >
          <div>{this.getImagesPrintSum("matt", "A5")} szt.</div>
          <div>{this.getImagesPrintSum("glossy", "A5")} szt.</div>
        </FlexPair>

        <FlexPair
          left="Wydruk A4"
          className={styles.item}
          nestedClassName={`${styles["right-menu-item"]} ${styles["align-right"]}`}
        >
          <div>{this.getImagesPrintSum("matt", "A4")} szt.</div>
          <div>{this.getImagesPrintSum("glossy", "A4")} szt.</div>
        </FlexPair>

        <FlexPair
          left="Wydruk A3"
          className={styles.item}
          nestedClassName={`${styles["right-menu-item"]} ${styles["align-right"]}`}
        >
          <div>{this.getImagesPrintSum("matt", "A3")} szt.</div>
          <div>{this.getImagesPrintSum("glossy", "A3")} szt.</div>
        </FlexPair>
      </React.Fragment>
    );
  };

  renderSummarySection = () => {
    return (
      <div className={styles.section}>
        <div className={styles.header}>Podsumowanie</div>
        <div className={styles["items-container"]}>
          <FlexPair
            left="Ilość wybranch zdjęć"
            right={this.getImagesPickedSum().toString()}
            className={styles.item}
          />
          {this.renderSummaryPrintsFragment()}
        </div>
      </div>
    );
  };

  renderInfoSection = (imageNumber, activeImageData, imagesData) => {
    return (
      <div className={styles.section}>
        <div className={styles.header}>
          Zdjęcie {imageNumber} / {imagesData.length}
        </div>
        <div className={styles["items-container"]}>
          <FlexPair
            left="Zdjęcie wybrane"
            right={
              <Switch
                active={activeImageData.picked}
                onChange={this.props.onImagePickedChanged}
              />
            }
            className={styles.item}
          />
        </div>
      </div>
    );
  };

  render() {
    const { imageNumber, imagesData, activeImageData, onCommentChange } =
      this.props;

    return (
      <div className={styles.container}>
        {this.renderInfoSection(imageNumber, activeImageData, imagesData)}
        {this.renderCommentSection(activeImageData, onCommentChange)}
        {this.renderPrintsSection()}
        {this.renderSummarySection()}
      </div>
    );
  }
}

export default PreferencesBar;
