import React, { Component } from "react";
import styles from "./input-number.module.scss";

class InputNumber extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div
          className={styles.decreaseButton}
          onClick={() => this.props.onChange(-1)}
        >
          -
        </div>
        <div className={styles.value}>{this.props.value}</div>
        <div
          className={styles.increaseButton}
          onClick={() => this.props.onChange(1)}
        >
          +
        </div>
      </div>
    );
  }
}

export default InputNumber;
